import lottie from 'lottie-web';
import { onEnterViewportListener } from '../helpers';
import { defaultOptions } from './constants';

/**
 * @param {HTMLElement} element
 * @private
 */
function _createAnimationForTargetElement(element) {
    const animation = lottie.loadAnimation(Object.assign({}, defaultOptions, {
        container: element,
        path: element.dataset.path
    }));

    animation.play();
}

/**
 * @private
 */
export default function initAhaAnimation() {
    const element = document.getElementById('lottie-aha-animation');

    if (!element) {
        return;
    }

    onEnterViewportListener(element, () => _createAnimationForTargetElement(element));
}
