import lottie from 'lottie-web';
import { defaultOptions } from './constants';
import { onEnterViewportListener } from '../helpers';

let element = null;
let animation = null;

/**
 * @private
 */
function _createAnimationForTargetElement() {
    animation = lottie.loadAnimation(Object.assign({}, defaultOptions, {
        container: element,
        path: element.dataset.path
    }));

    animation.playSegments([0, 110], true);
    animation.addEventListener('DOMLoaded', _bindEventListeners);
}

/**
 * @private
 */
function _bindEventListeners() {
    const group = element.querySelector('#lottie-presentatie-interactief');

    group.addEventListener('mouseenter', () => animation.playSegments([180, 211], false));
    group.addEventListener('mouseleave', () => animation.playSegments([211, 280], false));
}

/**
 * @private
 */
export default function initPresentationAnimation() {
    element = document.getElementById('lottie-presentation-visual');

    if (!element) {
        return;
    }

    onEnterViewportListener(element, () => _createAnimationForTargetElement());
}
