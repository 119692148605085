export default {
    init() {
        const toggleMenuButton = document.querySelector('.js-toggle-menu');

        toggleMenuButton.addEventListener('click', this._onToggleMenuClick.bind(this));
    },

    _onToggleMenuClick() {
        document.body.classList.toggle('mobile-nav-is-active');
    }
};
