import lottie from 'lottie-web';
import { onEnterAndLeaveViewportListener } from '../helpers';

let theme1AnimationStarted = false;
let theme2AnimationStarted = false;

function _startTheme1Animation(element, animation) {

    if (!theme1AnimationStarted) {
        animation.playSegments([
            [0, 745],
            [43, 745]
        ], true);

        theme1AnimationStarted = true;
    } else {
        animation.play(); // resume animation
    }

    const themeTitle = document.querySelector('.js-theme-title-1');

    animation.addEventListener('enterFrame', () => {
        let currentFrame = Math.round(animation.currentFrame);

        if (currentFrame === 1 || currentFrame === (720 - (1 + animation.firstFrame))) {
            themeTitle.innerHTML = 'Duurzaamheid';
        } else if (currentFrame === (240 - animation.firstFrame)) {
            themeTitle.innerHTML = 'Welzijn';
        } else if (currentFrame === (370 - animation.firstFrame)) {
            themeTitle.innerHTML = 'Innovatie';
        } else if (currentFrame === (590 - animation.firstFrame)) {
            themeTitle.innerHTML = 'Transitie';
        }
    });
}

function _startTheme2Animation(element, animation) {

    if (!theme2AnimationStarted) {
        animation.playSegments([
            [0, 660],
            [80, 660]
        ], true);

        theme2AnimationStarted = true;
    } else {
        animation.play(); // resume animation
    }

    const themeTitle = document.querySelector('.js-theme-title-2');

    animation.addEventListener('enterFrame', () => {
        let currentFrame = Math.round(animation.currentFrame);

        if (currentFrame === 1 || currentFrame === (590 - animation.firstFrame)) {
            themeTitle.innerHTML = 'Overheid';
        } else if (currentFrame === (135 - animation.firstFrame)) {
            themeTitle.innerHTML = 'Onderwijs';
        } else if (currentFrame === (265 - animation.firstFrame)) {
            themeTitle.innerHTML = 'Organisaties';
        } else if (currentFrame === (420 - animation.firstFrame)) {
            themeTitle.innerHTML = 'Bureau\'s';
        }
    });
}

export default function initThemeAnimation() {
    const theme1 = document.getElementById('lottie-theme-1'),
        theme2 = document.getElementById('lottie-theme-2');

    if (!theme1 || !theme2) {
        return;
    }

    const theme1Animation = lottie.loadAnimation({
        container: theme1,
        autoplay: false,
        loop: true,
        path: theme1.dataset.path
    });

    const theme2Animation = lottie.loadAnimation({
        container: theme2,
        autoplay: false,
        loop: true,
        path: theme2.dataset.path
    });

    onEnterAndLeaveViewportListener(theme1, () => _startTheme1Animation(theme1, theme1Animation), () => theme1Animation.pause());
    onEnterAndLeaveViewportListener(theme2, () => _startTheme2Animation(theme2, theme2Animation), () => theme2Animation.pause());
}
