/**
 * Observes if animation element has entered the viewport
 *
 * @param {Node} element
 * @param {Function} callback
 *
 * @private
 */
export function onEnterViewportListener(element, callback) {
    const observerConfig = {
        threshold: 0.8 // percentage of element that needs to be in viewport
    };

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            const { isIntersecting, intersectionRatio } = entry;
            if (isIntersecting === true || intersectionRatio > 0) {
                observer.disconnect();
                callback();
            }
        });
    }, observerConfig);

    observer.observe(element);
}

/**
 * @param {Node} element
 * @param {Function} onEnter
 * @param {Function} onLeave
 */
export function onEnterAndLeaveViewportListener(element, onEnter, onLeave) {
    const observerConfig = {
        threshold: [0, 0.8] // trigger callback when leaving and entering view
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            const { isIntersecting, intersectionRatio } = entry;
            if (isIntersecting === true || intersectionRatio > 0) {
                onEnter();
            } else {
                onLeave();
            }
        });
    }, observerConfig);

    observer.observe(element);
}
