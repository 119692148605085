import lottie from 'lottie-web';
import { onEnterViewportListener } from '../helpers';
import { defaultOptions } from './constants';

let element = null;
let animation = null;

/**
 * @private
 */
function _createAnimationForTargetElement() {
    animation = lottie.loadAnimation(Object.assign({}, defaultOptions, {
        container: element,
        path: element.dataset.path
    }));

    animation.playSegments([0, 140], true);
    animation.addEventListener('DOMLoaded', _bindEventListeners);
}

/**
 * @private
 */
function _bindEventListeners() {
    const group = element.querySelector('#ClickHover');

    group.addEventListener('mouseenter', () => animation.playSegments([150, 161], false));
    group.addEventListener('mouseleave', () => animation.playSegments([180, 210], false));
}

/**
 * @private
 */
export default function initAnimationVisual() {
    element = document.getElementById('lottie-animation-visual');

    if (!element) {
        return;
    }

    onEnterViewportListener(element, () => _createAnimationForTargetElement());
}
