import lottie from 'lottie-web';
import { defaultOptions } from './constants';
import { onEnterViewportListener } from '../helpers';

let animation = null;
let element = null;

/**
 * @private
 */
function _createAnimationForTargetElement() {
    animation = lottie.loadAnimation(Object.assign({}, defaultOptions, {
        container: element,
        path: element.dataset.path
    }));

    animation.playSegments([0, 210], true);
    animation.addEventListener('DOMLoaded', _bindEventListeners);
}

/**
 * @private
 */
function _bindEventListeners() {
    const group = element.querySelector('#ClickHover');

    if (group) {
        group.addEventListener('mouseenter', () => animation.playSegments([240, 300], false));
    }
}

/**
 * @private
 */
export default function initInfographicAnimation() {
    element = document.getElementById('lottie-infographic-visual');

    if (!element) {
        return;
    }

    onEnterViewportListener(element, () => _createAnimationForTargetElement());
}
