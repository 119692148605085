// libs
import * as lottieAnimations from './modules/lottie-animation/lottieAnimations';
import spotlight from './modules/spotlight';
import header from './modules/header';

function _carouselLoader() {
    const carouselElement = document.querySelector('.js-carousel');

    if (!carouselElement) {
        return;
    }

    // @todo find out how to load carousel as class so element can be passed
    import(/* webpackChunkName: "carousel" */ './modules/carousel').then(carousel => carousel.default.init());
}

document.addEventListener('DOMContentLoaded', () => {
    _carouselLoader();
    lottieAnimations.init();
    spotlight.init();
    header.init();
});
