import lottie from 'lottie-web';
import { defaultOptions } from './constants';

/**
 * Should be usable in most cases
 * Support playing segments on mouseOver and adding autoPlay delay
 */
class DefaultAnimation {
    constructor(element, autoplay = true, loop = false, initialSegments = null, mouseEnterSegments = null, autoplayDelay = 0) {
        this.element = element;
        this.loop = loop;
        this.initialSegments = initialSegments;
        this.mouseEnterSegments = mouseEnterSegments;
        this.autoplay = autoplay;
        this.autoplayDelay = autoplayDelay;

        if (this.element === null) {
            return;
        }

        this.initAnimation();
    }

    initAnimation() {
        this.animation = lottie.loadAnimation(Object.assign({}, defaultOptions, {
            container: this.element,
            path: this.element.dataset.path,
            loop: this.loop,
            assetsPath: this.element.dataset.assetsPath || ''
        }));

        if (this.autoplay) {
            // We need to play when DOM is loaded, else it will ignore the defined segments
            this.animation.addEventListener('DOMLoaded', this.play);
        }

        this.animation.addEventListener('DOMLoaded', this._bindMouseEnterAnimation);
    }

    play = () => {
        setTimeout(() => {
            if (this.initialSegments) {
                this.animation.playSegments(this.initialSegments, true);
            } else {
                this.animation.play();
            }
        }, this.autoplayDelay);
    }

    _bindMouseEnterAnimation = () => {
        if (this.mouseEnterSegments === null) {
            return;
        }

        this.element.addEventListener('mouseenter', () => {
            this.animation.playSegments(this.mouseEnterSegments, false);
        });
    }
}

export default DefaultAnimation;
