import { onEnterAndLeaveViewportListener } from './helpers';

class Spotlight {
    constructor(spotlightElement) {
        this._spotlightElement = spotlightElement;
        this._video = spotlightElement.querySelector('.spotlight__video');

        // @todo make this a spotlightVideo class as it has no options for images

        if (!this._video) {
            return;
        }

        // context bindings
        this._resume = this._resume.bind(this);
        this._pause = this._pause.bind(this);
        this._toggleSound = this._toggleSound.bind(this);
        this._removeLoader = this._removeLoader.bind(this);
        this._onVisibilityChange = this._onVisibilityChange.bind(this);

        // events
        this._initEventListeners();
    }

    _initEventListeners() {
        this._audioControl = this._spotlightElement.querySelector('.spotlight__audio-control');

        this._audioControl.addEventListener('click', this._toggleSound);
        this._video.addEventListener('canplay', this._removeLoader);
        document.addEventListener('visibilitychange', this._onVisibilityChange);

        onEnterAndLeaveViewportListener(this._spotlightElement, this._resume, this._pause);
    }

    _toggleSound(event) {
        event.preventDefault();

        this._audioControl.classList.toggle('is-muted', !this._video.muted);
        this._video.muted = !this._video.muted;
    }

    _removeLoader() {
        this._spotlightElement.classList.remove('is-loading');
    }

    _resume() {
        this._video.play();
    }

    _pause() {
        this._video.pause();
    }

    /**
     * Pauses video when user switches to different browser tab
     */
    _onVisibilityChange() {
        if (document.visibilityState === 'hidden') {
            this._pause();
        } else {
            this._resume();
        }
    }
}

/**
 *  There can only be one spotlight element
 */
export default {
    init() {
        const spotlightElement = document.querySelector('.js-spotlight');

        if (!spotlightElement) {
            return;
        }

        new Spotlight(spotlightElement);
    }
};
