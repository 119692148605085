import { onEnterViewportListener } from '../helpers';
import initAnimationVisual from './animationVisual';
import initAhaAnimation from './ahaAnimation';
import initInfographicAnimation from './infographicAnimation';
import initPresentationAnimation from './presentationAnimation';
import initHomepageHeroAnimation from './homepageHero';
import initThemeAnimation from './themeAnimation';
import DefaultAnimation from './DefaultAnimation';

const LOTTIE_ANIMATION_SELECTOR = '.js-load-lottie-animation';

/**
 * @param {Node} item
 * @private
 */
function _loadAnimation(item) {
    const dataOptions = item.dataset;

    const animation = new DefaultAnimation(
        item,
        dataOptions.autoplay === 'true',
        dataOptions.loop === 'true',
        dataOptions.initialSegments ? JSON.parse(dataOptions.initialSegments) : null,
        dataOptions.mouseoverSegments ? JSON.parse(dataOptions.mouseoverSegments) : null,
        dataOptions.autoplayDelay || 0
    );

    onEnterViewportListener(item, () => animation.play());
}

/**
 * This is used when animation isn't specifically controlled
 * @private
 */
function _initManagedAnimations() {
    const items = document.querySelectorAll(LOTTIE_ANIMATION_SELECTOR);

    if (!items.length) {
        return;
    }

    items.forEach((item) => _loadAnimation(item));
}

export function init() {
    _initManagedAnimations();

    // custom animations
    // @todo with newer DefaultAnimation class a lot of these custom animation can be removed
    initAhaAnimation();
    initAnimationVisual();
    initInfographicAnimation();
    initPresentationAnimation();
    initHomepageHeroAnimation();
    initThemeAnimation();
}
