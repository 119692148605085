import lottie from 'lottie-web';
import { onEnterAndLeaveViewportListener } from '../helpers';

/**
 * @private
 */
export default function initHomepageHeroAnimation() {
    const element = document.getElementById('lottie-homepage-hero');

    if (!element) {
        return;
    }

    const animation = lottie.loadAnimation({
        container: element,
        autoplay: false,
        loop: true,
        path: element.dataset.path
    });

    let isStarted = false;

    onEnterAndLeaveViewportListener(element, () => {
        if (!isStarted) {
            animation.playSegments([
                [0, 139],
                [80, 139]
            ], true);

            isStarted = true;
        } else {
            animation.play(); // resume animation
        }
    }, () => animation.pause() );
}
